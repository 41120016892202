<template>
  <input-form
    component-name="form-create-moodboard"
    ref="tpl_root"
    :submit-title="moodboard ? $t.labels.duplicate : $t.labels.create_moodboard"
    :submit-icon="moodboard ? 'duplicate' : 'moodboard'"
    :submit-callback="handleSubmit"
    :success-callback="handleSuccess"
    :autoreset-fields="false"
    v-model="initialValue"
  >
    <form-kit
      type="text"
      name="name"
      :label="$t.labels.moodboard_name"
      autocomplete="off"
      :placeholder="$t.labels.type_here"
      validation="required"
    />
  </input-form>
</template>

<script setup>
import { useUserStore } from "@/stores/user";

const props = defineProps({
  moodboard: {
    type: Object,
    required: false,
  },
  createdCallback: {
    type: Function,
    required: false,
  },
});

const userStore = useUserStore();
const tpl_root = templateRef("tpl_root");
const nuxtApp = useNuxtApp();
const router = useRouter();
const { params } = useRoute();

// const emit = defineEmits(["created"]);

const initialValue = ref(
  props.moodboard
    ? {
        name: props.moodboard ? `${props.moodboard.name} copy` : null,
        id: props.moodboard ? props.moodboard.id : null,
      }
    : null
);

function handleSubmit(data) {
  if (!userStore.logged) {
    return;
  }

  const method = props.moodboard
    ? userStore.duplicateMoodboard
    : userStore.createMoodboard;

  return method(data)
    .then((data) => {
      if (!data) {
        tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
      }
      const { status, error } = data;

      if (status == "error") {
        if (error == "invalid_token") {
          userStore
            .logout()
            .then((response) => {
              router.push(`/${params.country}/${params.locale}/signin`);
              return response;
            })
            .catch((error) => {});
        } else {
          tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
        }
      } else {
        if (props.moodboard) {
          router.push(
            `/${params.country}/${params.locale}/account/personal-area/moodboards`
          );
        } else {
          if (props.createdCallback) {
            props.createdCallback(data);
          }
        }
      }

      return data;
    })
    .catch((error) => {
      console.log("error", error);
      tpl_root.value.handleErrors([nuxtApp.$t.labels.something_went_wrong]);
    });
}

function handleSuccess(data) {}
</script>
